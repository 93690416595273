@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap');

html {
    background: linear-gradient(to top, black, rgb(24, 24, 117));
    background-size: cover;
    height: 100%;
}

body {
    margin: 0 auto;
    width: 1920px;


}


.header-reg {
    background-color: rgb(14, 67, 167);
    padding: 25px;
    margin-top: 15px;
    margin-bottom: 0px;

    border-radius: 3px;

}

.login-text {
    text-align: center;
    color: rgb(192, 189, 189);
    font-family: 'Comfortaa';
    font-size: 50px;
    letter-spacing: 0.4em;
}

.create-folder p {
    color: rgb(0, 0, 0);
    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 18px;
}

.input-main {
    margin-top: 50px;

    padding: 4px;
    padding-left: 10px;
    border-radius: 10px;
    border: none;
    background-color: rgb(255, 255, 255)
}

.create-folder input {
    padding: 5px;
    border-radius: 5px;
    border: none;
    text-align: center;
    margin-top: 20px;
}

.create-folder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.create-folder button {
    background-color: green;
    border: none;
    border-radius: 10px;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    width: 250px;

    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 13px;
    color: white;

    margin-top: 30px;
}


.new-folder-dialog {
    background-color: rgb(224, 222, 222);
    position: absolute;
    top: 450px;
    left: 1300px;
    padding: 20px;
    border-radius: 10px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.shield {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
}

.form-login p {
    color: white;

    color: rgb(192, 189, 189);
    font-family: 'Comfortaa';
    font-weight: 400px;
    font-size: 15px;
    margin-bottom: -35px;
}

.img-delete-folder {
    width: 25px;
    vertical-align: middle;
    margin-left: 3px;
    opacity: 0;
}




#upload-file {
    display: none;
}

.file-item {
    color: rgb(192, 189, 189);
    font-family: 'Comfortaa';
    font-weight: 400px;
    font-size: 15px;
    transition: background-color 1s;
    border-radius: 5px;

    animation: file-enter 1s;

}

.loader-screen {
    position: absolute;
    z-index: 800;
    top:600px;
    left: 1500px;
}

@keyframes file-enter {
    0% {
        opacity: 0;
        transform: translateY(-50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.back-hidden {
    background-color: gray;
    border: none;
    border-radius: 10px;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    width: 250px;
    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 13px;
    color: white;


    opacity: 0;
    transition: opacity 1s;

    animation: back-file 1s;
}

@keyframes back-file {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-200%);
    }
}

.back-inherit {
    background-color: gray;
    border: none;
    border-radius: 10px;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    width: 250px;
    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 13px;
    color: white;

    animation: go-file 1s;
}

@keyframes go-file {
    0% {
        opacity: 0;
        transform: translateX(-200%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.exit-new-folder {
    margin-left: 220px;
    margin-top: 10px;
    margin-bottom: -5px;
}

.file-item:hover {
    transition: background-color 0.5s;
    background-color: rgba(165, 165, 165, 0.384);
    border-color: red;
    border-radius: 5px;
    padding: 1px;
    margin-bottom: 5px;
}

.img-file-folder {
    vertical-align: middle;
    width: 40px;

}

.block-auth {
    background:
        linear-gradient(217deg, rgba(117, 10, 150, 0.8), rgba(255, 0, 0, 0) 70.71%),
        linear-gradient(127deg, rgba(173, 13, 13, 0.562), rgba(0, 255, 0, 0) 70.71%),
        linear-gradient(336deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 255, 0) 70.71%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    width: 450px;
    margin: 200px auto;
    padding: 10px;
    border-radius: 10px;
    padding-bottom: 30px;

}

.form-login input {
    margin-top: 50px;

    padding: 4px;
    padding-left: 10px;
    border-radius: 10px;
    border: none;
    background-color: rgb(255, 255, 255)
}

.form-login {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.form-login button {
    margin-top: 30px;

    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 13px;

    border-radius: 10px;
    border: none;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
}


.header-nav {
    display: flex;
    justify-content: space-around;
}

.active {
    background: orange;
    padding: 5px;
    border-radius: 5px;
}

.header-reg a {
    color: rgb(255, 255, 255);
    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 18px;

    text-decoration: none;
}

.text-reg {
    text-align: center;
    color: rgb(192, 189, 189);
    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 30px;
    letter-spacing: 0.6em;
}

.reg-body p {
    color: white;

    color: rgb(192, 189, 189);
    font-family: 'Comfortaa';
    font-weight: 400px;
    font-size: 15px;
    margin-bottom: -35px;
}



.form-reg button {
    margin-top: 15px;

    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 13px;

    border-radius: 10px;
    border: none;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
}

.form-reg {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}



.reg-body {
    background:
        linear-gradient(217deg, rgba(117, 10, 150, 0.8), rgba(255, 0, 0, 0) 70.71%),
        linear-gradient(127deg, rgba(173, 13, 13, 0.562), rgba(0, 255, 0, 0) 70.71%),
        linear-gradient(336deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 255, 0) 70.71%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    width: 450px;
    margin: 200px auto;
    padding: 10px;
    border-radius: 10px;
    padding-bottom: 30px;
}

.logout img {
    vertical-align: middle;
    width: 23px;
}

.logout {
    color: white;
    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 17px;
}

.upload-file:hover {
    transform: scale(1.05);
    transition: transform 0.3s;
}

.upload-file {
    transition: transform 1s;
    background-color: orange;
    border: none;
    border-radius: 10px;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    width: 250px;
    margin-top: 60px;
    margin-bottom: 30px;
    text-align: center;

    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 13px;
    color: white;
}


.library-li p {
    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 13px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.3em;

}

.drop-area-hidden {
    opacity: 0;
    transition: opacity 1s;
}

.drop-area-visible {
    opacity: 1;
    transition: opacity 1s;
    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 30px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.3em;


    border: dashed;
    border-color: white;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-folder {
    background-color: green;
    border: none;
    border-radius: 10px;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    width: 250px;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 25px;

    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 13px;
    color: white;
    transition: transform 1s;
}

.library-li a {
    text-decoration: none;

    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 13px;
    color: white;
}

.library-li hr {
    width: 350px;
}
.navigation hr {
    margin-top: 50px;
}


.library-nav {

    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


    z-index: 1;
}

.library-li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.background-library {

    background-image: url(image/mariya-shevchenko-kardiogramma-gor-462677-beluha.jpg);
    background-repeat: no-repeat;
    filter: blur(4px);
    background-size: cover;

    border-radius: 10px;

    z-index: 0;

}

.border-search {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 10px;
    border: none;
}

.border-search-but {
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    margin-left: -16px;
    border-radius: 3px;

}

.filter-blur {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 450px;
}

.navigation h1 {
    text-align: center;

    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 30px;
    text-transform: lowercase;
    letter-spacing: 4px;
    color: white;
}

.filter-blur>div {
    grid-column: 1;
    grid-row: 1;

}

.library-photo {
    width: 1980px;
    height: 1080px;
    border-radius: 10px;
    background-color: rgb(29, 28, 28);
}

main {
    display: flex;
    flex-direction: row;
}

.file-selected {
    background-color:rgba(165, 165, 165, 0.384);
    animation: file-enter 1s;
    color: rgb(192, 189, 189);
    font-family: 'Comfortaa';
    font-weight: 400px;
    font-size: 15px;
    border-radius: 5px;
    margin-bottom: 7px;
}

.file-selected:hover .file-but-download,
.file-selected:hover .file-but-delete {
    opacity: 1;

    transition: opacity 1s;
}

.edit-logo {
    display: none;
    margin-left: 350px;
}

.default-img {
    height: 970px;
    width: 1450px;
    border-radius: 5px;
    margin-left: 10px;
    margin-top: 20px;

    
}

@keyframes imgOpen {
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}
.block-item span {
    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 13px;
    color: white;
    margin-top: 10px;

    background-color: orange;
    padding: 10px;
    border-radius: 5px;

}

.img-full-screen {
    z-index: 100;
    position: absolute;
    left: 400px;
    top: 30px;
    height: 1200px;
    width: 1800px;
    margin-right: 200px;
}


.block-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

}

.new-folder:hover {
    transform: scale(1.05);
    transition: transform 0.3s;
}

.block-item img {
    border-radius: 15px;
}



.head-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.page-photo {
    display: flex;
    justify-content: center;



}

.page-photo span {
    background-color: white;
    margin-top: 50px;
    margin-left: 20px;
    padding: 5px;
}

.profile-block {
    background-color: rgb(31, 30, 30);
    height: 1000px;
    width: 1000px;
    margin: 0 auto;
    margin-top: 100px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.file-but-download {
    background-color: white;
    border: none;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 1s;
    animation-delay: 3s;
    margin-left: 10px;
}

.info-text {
    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 17px;
    color: white;
}

.uploader-file-uploader-bar {
    width: 0%;
    height: 20px;
    background-color: green;
    border-radius: 10px;
    margin-top: 10px;
}

.uploader-file-header {
    background-color: orange;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.uploader::-webkit-scrollbar {
    display: none;
}



.uploader {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 120px;
    left: 1920px;
    

    animation: uploader-anim 500ms;

}

@keyframes uploader-anim {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.uploader-file-remove {
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    padding-bottom: 2px;
    padding-top: 2px;
    padding-right: 10px;
}

.uploader-file-persent {
    text-align: center;
}

.uploader-close {
    float: right;
    border: none;
    border-radius: 10px;
    padding-left: 20px;
    padding-bottom: 2px;
    padding-top: 2px;
    padding-right: 20px;
}

.uploader-title {
    text-align: center;
    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 16px;
    color: black;
}

.uploader-file-progress-bar {
    background-color: gray;
    height: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
}



.file-but-delete {
    background-color: orange;
    border-radius: 10px;
    border: none;
    opacity: 0;
    transition: opacity 1s;
    animation-delay: 3s;
    margin-left: 5px;
    margin-right: 5px;
}

.width-box-edit {
    display: flex;
    align-items: center;
}
.box-check {
    width: 32px;
    height: 32px;
    display: block;
    position: relative;
    
}

.checked {
    display: none;
    float: left;
}

.checked + span {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: -5px;
    margin-left: 20px;
    background: url(assets/icon/sprayEdit.png);
    animation: imgNotChecked 500ms;
}

 .checked:checked + span {
    background-position: 0 32px;

    animation: imgChecked 500ms;
}

@keyframes imgNotChecked {
    100%{
        transform: scale(1);
    }
    0%{
        transform: scale(0);
    }
}

@keyframes imgChecked {
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}


.file-but-delete img {
    width: 15px;


}

.file-item:hover .file-but-download,
.file-item:hover .file-but-delete,
.file-but-delete.visible,
.file-but-download.visible {
    opacity: 1;

    transition: opacity 1s;
}

.profile-block h1 {
    font-family: 'Comfortaa';
    font-weight: 900px;
    font-size: 30px;
    color: white;
    text-align: center;
    margin-top: 50px;
}

.file-but-download img {
    width: 15px;

}


.profile-item {
    background-color: orange;
    text-align: center;
    display: inline;
    padding: 10px;
    padding-bottom: 3px;
    padding-top: 3px;
    margin: 0 auto;
    border-radius: 5px;
}