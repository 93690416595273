@media only screen and ( max-width: 2400px ) {
    body {
        width: 1920px;
        height: 1080px;
    }
    .header-reg {
        width: 1870px;
        margin: 0 auto;
        margin-bottom: 0px;
    }
    .uploader {
        position: absolute;
        z-index: 100;
        top: 710px;
        left: 90px;
    }
    .library-nav {
        width: 500px;
        margin: 0 auto;
    }
    .library-photo {
        width: 1900px;
        height: 950px;
    }

    .default-img {
        margin-top: 10px;
        margin-left: 120px;
        height: 850px;
        width: 1300px;
    }
    .library-nav hr {
        width: 100%;
    }

    .profile-block {
        margin: 100px auto;
        width: 900px;
        height: 800px;
    }

    .img-full-screen {
        z-index: 100;
        position: absolute;
        left: 50px;
        top: 30px;
        height: 1000px;
        width: 1800px;
        margin-right: 200px;
    }
    

    .new-folder-dialog {
        left: 350px;
    }

    .block-auth {
        margin: 300px auto;
    }

    .reg-body {
        margin: 300px auto;
    }
 
}
@media only screen and ( max-width: 1280px ) {
    .checked + span {
        display: inherit;
    }
    .edit-logo {
        display: inherit;
    }

    html {
        background-size: cover;
        height: 1200px;
    }
    body {
        width: 1280px;
        height: 800px;
    }
    .header-reg {
        width: 1200px;
        margin: 30px auto;
        margin-bottom: 0px;
    }
    .uploader {
        position: absolute;
        z-index: 100;
        top: 710px;
        left: 90px;
    }
    .library-nav {
        width: 400px;
        margin: 0 auto;
    }
    .library-photo {
        width: 815px;
        height: 950px;
    }
    .background-library {
        margin-left: 20px;
    }

    .default-img {
        margin-top: 20px;
        margin-left: 60px;
        height: 700px;
        width: 700px;
    }
    .library-nav hr {
        width: 100%;
    }

    .profile-block {
        margin: 100px auto;
        width: 900px;
        height: 800px;
    }

    .img-full-screen {
        z-index: 100;
        position: absolute;
        left: 50px;
        top: 100px;
        height: 1000px;
        width: 1200px;
        margin-right: 200px;
    }
    

    .new-folder-dialog {
        left: 350px;
    }

    .block-auth {
        margin: 300px auto;
    }

    .reg-body {
        margin: 300px auto;
    }
}

@media only screen and ( max-width: 1024px ) {
    .header-reg {
        width: 1024px;
        margin: 10px auto;
    }
    html{
        height: 900px;
    }
    body{
        width: 1024;
        height: 600;
    }

    .profile-block {
        margin: 30px auto;
        width: 1000px;
        height: 500px;
    }
    .library-photo {
        width: 628px;
        height: 800px;
        margin-left: 0px;
    }
    .library-nav {
        width: 150px;
        margin: 0 auto;
    }
    .default-img {
        margin-top: 30px;
        margin-left: 15px;
        height: 500px;
        width: 600px;
    }
    .filter-blur {
        margin: 0 100px ;
        margin-right: 0px;
    }
}

@media only screen and ( max-width: 820px ) {
    html {
        background-size: cover;
        height: 1180px;
    }
    body{
        width: 820px;
        height: 890px;
    }

    .header-reg {
        width: 750px;
        margin: 20px auto;
    }

    .default-img {
        height: 400px;
        width: 310px;
        margin: 150px auto;
        margin-left: 30px;
    }

    .library-nav hr {
        width: 100%;
    }
    .library-nav {
        width: 250px;
        height: 750px;
        margin: 0 auto;
        margin-left: -5px;
    }

    .library-photo {
        width: 360px;
    }
    .background-library {
        margin-left: -90px;
    }

    .edit-logo {
        display: inherit;
    }

    .img-full-screen {
        z-index: 100;
        position: absolute;
        left: 10px;
        top: 150px;
        height: 800px;
        width: 800px;
        margin-right: 200px;
    }
    .uploader {
        display: none;
    }
    .new-folder-dialog {
        margin-top: -50px;
        margin-left: -280px;
        margin-right: 0px;
    }
    .shield
    .wrap{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .block-auth {
        margin: 200px auto;
        width: 350px;
    }
    .profile-block {
        margin: 130px auto;
        height: 400px;
    }

    .reg-body {
        margin: 180px auto;
        width: 350px;
    }

    .checked + span {
        display: inherit;
    }
}

@media only screen and ( max-width: 500px ) {
    html {
        background-size: cover;
        height: 914px;
    }
    body{
        width: 350px;
        height: 890px;
        
    }

    .header-reg {
        width: 320px;
        margin: 20px auto;
    }

    .default-img {
        margin-top: 10px;
        margin-left: 120px;
        height: 850px;
        width: 1300px;
    }

    .library-nav hr {
        width: 100%;
    }
    .library-nav {
        width: 200px;
        height: 750px;
        margin: 0 auto;
        margin-left: -20px;
    }

    .library-photo {
        display: none;
    }

    .edit-logo {
        display: inherit;
    }
    .background-library {
        margin-left: -100px;
        margin-right: -20px;
    }

    .img-full-screen {
        z-index: 100;
        position: absolute;
        left: 200px;
        top: 30px;
        height: 1000px;
        width: 1800px;
        margin-right: 200px;
    }
    .uploader {
        display: none;
    }
    .new-folder-dialog {
        margin-top: -50px;
        margin-left: -280px;
        margin-right: 0px;
    }
    .shield
    .wrap{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .block-auth {
        margin: 200px auto;
        width: 350px;
    }
    .profile-block {
        margin: 130px auto;
        height: 400px;
    }

    .reg-body {
        margin: 180px auto;
        width: 350px;
    }

    .checked + span {
        display: inherit;
    }
 
}

